/* eslint-disable max-len */
import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  EnvironmentPartial,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import styles from './styles/dataTable.module.scss';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const DataTable = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataTable;
    return <Component {...props} />;
  },
});

const dataTableRowsSnippet = require('raw-loader!../../../../data/snippets/data-table-rows.example');
const dataTableRowsElementSnippet = require('raw-loader!../../../../data/snippets/data-table-rows-element.example');
const dataTableSnippet = require('raw-loader!../../../../data/snippets/data-table.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Data Table"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-data-visualization--docs#data-table"
      />
      <div className={styles.codeSnippetWrapper}>
        <CodeSnippet
          scope={{ React: React, DataTable: DataTable }}
          code={dataTableSnippet}
          platform="react"
          gitHubLink="datatable"
        />
      </div>

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="data table" />

          <PropListItem
            name="columnContentTypes"
            types={["('text' | 'numeric' | 'selectmark' | 'element')[]"]}
            isRequired>
            <Text>
              The content types of each column in your data table. Acceptable
              values are:
            </Text>
            <List type="unordered">
              <li>
                <code>text</code>
              </li>
              <li>
                <code>numeric</code>
              </li>
              <li>
                <code>selectmark</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="columnHeaders" types={['string[]']} isRequired>
            <Text>The text to display in the column headers.</Text>
          </PropListItem>

          <PropListItem name="columnShowSortArrows" types={['boolean[]']}>
            <Text>
              Whether or not each column in your data table should show sort
              arrows. This also switches on the sort functionality for a column
              when <code>true</code>.
            </Text>
          </PropListItem>

          <EnvironmentPartial />

          <PropListItem name="rows" types={['Rows[]']} isRequired>
            <Text>
              The data to show. Each row must include an id and data like so:
            </Text>
            <CodeSnippet
              scope={{ React: React }}
              code={dataTableRowsSnippet}
              platform="react"
              id="dataTableRows"
              disableCodeEditing
            />
            <Text>
              To include arbitrary React elements within the table cells, pass
              an object with <code>value</code> and <code>element</code> props
              like so:
            </Text>
            <CodeSnippet
              scope={{ React: React }}
              code={dataTableRowsElementSnippet}
              platform="react"
              id="dataTableRowsElement"
              disableCodeEditing
            />
          </PropListItem>

          <PropListItem name="rowsChecked" types={['string[]']}>
            <Text>Determines which rows have their Select Marks checked.</Text>
          </PropListItem>

          <PropListItem name="rowsSelected" types={['string[]']}>
            <Text>Determines which rows are selected.</Text>
          </PropListItem>

          <PropListItem name="showTableBorder" types={['boolean']}>
            <Text>
              Determines whether a border surrounds the entire data table.
            </Text>
          </PropListItem>

          <PropListItem name="size" types={['string']}>
            <Text>
              Determines the size of the data table. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>xsmall</code>
              </li>
              <li>
                <code>small</code>
              </li>
              <li>
                <code>medium</code> - (default)
              </li>
              <li>
                <code>large</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="style" types={['string']}>
            <Text>
              Determines the style of the data table. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>default</code> - (default)
              </li>
              <li>
                <code>freeform</code>
              </li>
              <li>
                <code>striped</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="title" types={['string']}>
            <Text>
              Determines the title of the data table. Will be rendered as a{' '}
              <code>{'<caption>'}</code> element. If no <code>title</code> is
              provided, please use{' '}
              <Link href="#aria-describedby">
                <code>aria-describedby</code>
              </Link>{' '}
              instead.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Functional">
          <PropListItem name="columnDefaultSortIndex" types={['number']}>
            <Text>
              Pass in the index of the column you want a default sort applied
              to. Starts at 0.
            </Text>
          </PropListItem>

          <PropListItem name="columnDefaultSortDirection" types={['string']}>
            <Text>
              Determines how you want your default sort applied. Acceptable
              values are:
            </Text>
            <List type="unordered">
              <li>
                <code>ascending</code> - default
              </li>
              <li>
                <code>descending</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="freezeFirst" types={['string']}>
            <Text>
              Determines whether to freeze the first row or column when
              scrolling. Acceptable values are:
            </Text>
            <List type="unordered">
              <li>
                <code>row</code> - default
              </li>
              <li>
                <code>column</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onRowClick" types={['(rowId: string) => void']}>
            <Text>
              Responds to a row being clicked. Passes back the id of the row.
            </Text>
          </PropListItem>

          <PropListItem
            name="onSelectMarkClick"
            types={['(rowId: string) => void']}>
            <Text>
              Responds to a row's Select Mark being clicked. Passes back the id
              of the row. If the Select Mark in the header is clicked,
              'all-rows-checked' is passed back.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Accessibility">
          <PropListItem name="aria-describedby" types={['string']}>
            <Text>
              If you choose not to use the{' '}
              <Link href="#title">
                <code>title</code> property
              </Link>
              , use the <code>aria-describedby</code> property to associate the
              table with an alternate header element for accesssibility
              purposes. See the{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_aria-describedby_attribute">
                MDN documentation
              </Link>{' '}
              to learn more.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            Including <code>selectmark</code> as a column content type will
            automatically put a select mark in that row, but your data must
            account for that (you can put an empty string in for that particular
            column).
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Data Visualization / Data Table"
      />
    </PlatformTab>
  );
};

export default WebTab;
